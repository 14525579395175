require("file-loader?name=[name].[ext]!./index.html");
require("file-loader?name=[name].[ext]!./robots.txt");
require("file-loader?name=[name].[ext]!./favicon.ico");

import React from "react";
import ReactDOM from "react-dom";

import cursorImg from "./img/cursor.gif";

/*
 * EmailLink
 */
class EmailLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mailto_rot13: "ounhtu@ounhtu.pbz",
    };
  }

  render() {
    const mailto = this.state.mailto_rot13.replace(
      /[a-zA-Z]/g,
      this.decode_rot13
    );
    return <a href={"mailto:" + mailto}>{mailto}</a>;
  }

  decode_rot13(c) {
    return String.fromCharCode(
      (c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26
    );
  }
}

/*
 * ShillPrompt
 */
class ShillPrompt extends React.Component {
  // TODO: data?
  static KEY_CODES = {
    ENTER: 13,
    SHIFT: 16,
    MIN: 31,
    MAX: 127,
    A: 65,
    Z: 90,
  };
  static MAX_HEIGHT = 32768; // ???

  constructor(props) {
    super(props);

    this.state = {
      buffer: "",
      modifier_mask: false,
      shift_mask: false,
    };
  }

  handleKeyDown = (e) => {
    if (this._isModifierKey(e)) {
      // Drop all CTRL/OPT/ALT
      this.setState({
        modifier_mask: true,
      });
    } else if (e.keyCode == ShillPrompt.KEY_CODES.SHIFT) {
      this.setState({
        shift_mask: true,
      });
    }
  };

  handleKeyUp = (e) => {
    let kc = e.keyCode;

    if (!kc) {
      return;
    }
    if (this._isModifierKey(e)) {
      this.setState({
        modifier_mask: false,
      });
      return;
    }
    if (kc == ShillPrompt.KEY_CODES.SHIFT) {
      this.setState({
        shift_mask: false,
      });
      // TODO: return?
    }
    if (!!this.state.modifier_mask) {
      return;
    }

    if (
      (kc > ShillPrompt.KEY_CODES.MIN && kc < ShillPrompt.KEY_CODES.MAX) ||
      kc == ShillPrompt.KEY_CODES.ENTER
    ) {
      if (
        kc >= ShillPrompt.KEY_CODES.A &&
        kc <= ShillPrompt.KEY_CODES.Z &&
        !this.state.shift_mask
      ) {
        kc += 32;
      }

      switch (kc) {
        case ShillPrompt.KEY_CODES.ENTER:
          this.handleInput(this.state.buffer);
          break;
        default:
          this.setState({
            buffer: this.state.buffer + String.fromCharCode(kc),
          });
          window.scroll(0, ShillPrompt.MAX_HEIGHT); // yes yes it's dirty
          break;
      }
    } else {
      return;
    }
  };

  clearInsertion() {
    this.setState({
      buffer: "",
    });
  }

  _isModifierKey = (e) => {
    return e.ctrlKey || e.altKey || e.keyIdentifier == "Meta";
  };

  handleInput = (input) => {
    if (this.props.onInput) {
      this.props.onInput(input);
    }
    this.clearInsertion();
  };

  componentDidMount() {
    document.body.onkeydown = this.handleKeyDown;
    document.body.onkeyup = this.handleKeyUp;
  }

  componentWillUnmount() {
    document.body.onkeydown = null;
    document.body.onkeyup = null;
  }

  render() {
    return (
      <div className="prompt">
        {this.props.hostname}:~ {this.props.username}$
        <span className="insertion" ref="insertion">
          {" "}
          {this.state.buffer}
        </span>
        <span className="cursor">
          <img src={cursorImg} alt="█" className="blink" />
        </span>
      </div>
    );
  }
}
//<Cursor ref="cursor" />

/*
 * ShillOutput
 */
class ShillOutput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lines: [],
    };
  }

  render() {
    return <span className="shill-output">{this.state.lines.join("\n")}</span>;
  }

  pushHistory(input) {
    const tokenizedInput = input.split(" ");
    let newLines = [];
    newLines.push(
      this.props.hostname + ":~ " + this.props.username + "$ " + input
    );
    if (tokenizedInput.length > 0) {
      newLines.push("-BOHA: " + tokenizedInput[0] + ": command not found");
    }
    this.setState({
      lines: this.state.lines.concat(newLines),
    });
  }
}

/*
 * Shill
 */
class Shill extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hostname: "gibson",
      username: "boha",
    };
  }

  handleInput = (input) => {
    // Track command (Google Analytics)
    gtag("event", "prompt_input", { content: input });
    // Log input
    this.refs.output.pushHistory(input);
  };

  render() {
    return (
      <div className="shill">
        <ShillOutput
          hostname={this.state.hostname}
          username={this.state.username}
          ref="output"
        />
        <ShillPrompt
          hostname={this.state.hostname}
          username={this.state.username}
          onInput={this.handleInput}
        />
      </div>
    );
  }
}

ReactDOM.render(<Shill />, document.getElementById("shill"));

[...document.querySelectorAll(".email-link")].forEach((el) => {
  ReactDOM.render(<EmailLink />, el);
});
